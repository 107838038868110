/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */



/* ---- Brand Colors ---- */
$brand-first: #c09551;
$brand-second: #21366C;



/* ---- Colors ---- */
$dark: #44403f;
$gray: #757575;
$gray-light: #808080;
$light: #ececec;
$lighter: #f7f7f7;
$lightest: #fafafa;
$light-bg: #f4f4f4;
$white: #fff;
$error-red: $brand-first;
$success-green: #0F9D58;
$highlight-text-bg: #17DA5B;



/* ---- Colors List ---- */
$label-colors:(
  red: #852A15,
  green: #82B82F,
  gray: #7E8C95,
  yellow: #FCD741,
  orange: #B85D0A,
  dark: $dark,
  turquoise: #1F6779,
  navy: $brand-second,
  av-available: lighten($brand-second, 15%),
  av-reserved: #B85D0A,
  av-unavailable: #852A15
);

$status-colors:(
  av-available: lighten($brand-second, 15%),
  av-reserved: #B85D0A,
  av-unavailable: #852A15
);

$alert-colors:(
  danger: $error-red,
  success: $success-green
);

/* ---- Custom Colors ---- */
$cart-widget-color: $brand-first;
$filters-x-color: $brand-first;
$alt-bg-color: $lighter;
$error-page-bg: darken($brand-first, 15%);



/* ---- Text ---- */

// Font Family
$body-font: 'Merriweather', 'Helvetica Neue', Helvetica, sans-serif;
$heading-font: 'Merriweather', 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif;
$alt-heading-font: 'Montserrat', 'Merriweather', 'Helvetica Neue', Helvetica, sans-serif;

// Text Sizes
$body-text-size: 15px;
$body-weight: 300;
$line-hight: 1.8em;
$letter-spacing: 0;

// Heading Sizes
$heading-base-size: 3.2em;

// Text Color
$body-text-color: #777;
$heading-text-color: #111;
$link-color: $brand-first;



/* ---- White Space ---- */
$ws-s: 30px;
$ws-m: 60px;
$ws-l: 90px;



/* ---- Buttons ---- */
$btn-padding: 8px 38px;
$btn-ghost-padding: 7px 37px;
$btn-font: $alt-heading-font;
$btn-font-size: .9em;
$btn-letter-spaceing: 1px;
$btn-line-height: 1.8em;
$btn-color: $light;
$btn-bg: $brand-second;
$btn-rounded: 2px;
$btn-trans-time: .5s;



/* ---- Forms ---- */
$input-height: 40px;
$input-border-color: #ddd;
$input-border-color-focused: $gray;



/* ---- Navigation ---- */
$navbar-higth: 64px;
$navbar-background: #fff;
$navbar-border-color: $light;
$nav-links-font-size: .84em;
$nav-links-font-weight: 700;
$nav-links-letter-spacing: 2px;
$nav-links-color: $brand-second;
$nav-links-color-hover: $dark;
$nav-link-active-color: $dark;
// Dropdown
$dropdown-padding: 15px 25px 15px 25px;
$dropdown-background: rgba(255,255,255, 1);
$dropdown-link-padding: 11px 30px 11px 0;
$dropdown-link-letter-spacing: .5px;
$dropdown-link-line-height: 1.4em;
$dropdown-link-left-border: 3px solid $dark; // don`t change the 3px;
$dropdown-link-background: rgba(17,17,17, .05);
$dropdown-link-shift: 0; // no shift on 25px;
$dropdown-header-padding: 0 25px;
$dropdown-header-font-size: .8em;
$dropdown-header-font: $heading-font;
$dropdown-header-color: $dark;
$dropdown-divider-color: #ddd;
$bg-solid-white: #fff;
$bg-solid-black: #111;
// Navbar Inverse
$navbar-inverse-background: $dark;
$navbar-inverse-border-color: #222;
$nav-inverse-links-color: $gray;
$nav-inverse-links-color-hover: $light;
$nav-inverse-link-active-color: $light;
$dropdown-inverse-background: rgba(17,17,17, .95);
$dropdown-inverse-link-background: rgba(255,255,255, .1);
$dropdown-inverse-link-left-border: 3px solid $light;
$dropdown-inverse-header-color: $light;
$dropdown-inverse-divider-color: #444;
$nav-inverse-searh-border-color: $light;



/* ---- Footer ---- */
$footer-bg: #e3e3e3;
$footer-copyright-bg: #222;
$footer-hr-color: #999;



/* ---- Other ---- */
$hr-color: #ccc;



/* ---- Media Queries Breakpoints ---- */
$screen-xs: 600px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xlg: 1440px;


$web-img-path : '../img/';